import styled from "styled-components";

export const Wrapper = styled.div`
  .team-dashboard {
    margin-top: 50px;
    .project-sum {
      height: 100%;
      min-heght: 300px;
      .item {
        position: relative;
        top: 50px;
      }
    }
    .sdt-container {
      height: 100%;
      .center {
        text-align: center;
        span {
          display: block;
          font-size: 40px;
          font-weight: bold;
          &.top {
            border-bottom: 1px solid rgb(227, 227, 227);
            padding: 10px 0 15px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .summary-item {
    h4 {
      svg {
        margin-right: 5px;
      }
    }
  }
  .detail-item {
    &.scroll {
      height: 100%;
      max-height: 180px;
      overflow: scroll;
      &.big {
        max-height: 235px;
      }
    }

    &.icon {
      svg {
        width: 200px;
        margin: auto;
      }
    }
    .item {
      p {
        padding-bottom: 10px;
      }

      svg {
        margin-top: 5px;
        width: 44px;
        margin-bottom: -5px;
      }
      &.ontrack {
        svg {
          color: #0071cd;
        }
      }
      &.inprogress {
        svg {
          color: #fec84d;
        }
      }
      &.at-risk {
        svg {
          color: #fd7e40;
        }
      }
    }
    table {
      td,
      tr,
      th {
        border: 0;
        &:first-child {
          padding-left: 15px;
        }
        p {
          font-size: 14px;
          font-weight: 700;
          line-height: 150% !important;
          span {
            font-weight: 400;
            color: #767676;
            font-size: 13px;
          }
        }
        a {
          color: #000;
          &:hover {
            color: #0071cd;
          }
        }
      }
      td {
        padding: 5px 16px !important;
      }
      th {
        font-size: 16px;
        color: #767676;
      }
      &.task {
        th {
          font-size: 14px;
        }
      }
    }
  }
  .pmo-pagination {
    p {
      &.MuiTablePagination-selectLabel,
      &.MuiTablePagination-displayedRows {
        margin-bottom: 0 !important;
      }
    }
  }
`;
