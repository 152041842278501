import React, { useEffect, useState } from "react";
import { Wrapper } from "./styles";
import Breadcrumb from "../../shared/Breadcrumbs";
import { Box,Container, Grid } from "@mui/material";
import { useAdminState } from "../../context/AdminContext";
import Loading from "../../shared/Loading";
import getProductionList from "../../services/getProductionList";
import DEV_TEAMS from "../../constants/teams";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Link, navigate } from "gatsby";
import moment from "moment";
import {
  CheckBadgeIcon,
  Cog6ToothIcon,
  ClipboardDocumentListIcon,
  UserGroupIcon,
  SparklesIcon,
  BuildingOffice2Icon,
  StarIcon,
} from "@heroicons/react/24/outline";
import StackedBarChart from "../../shared/StackedBarChart";
import DEV_SKILLS from "../../constants/devSkills";
import TableComp from "../../shared/Table";
import _ from "lodash";
import COST_SAVING_DATA from "../../constants/costSavingProject";

function LocalDevDashboard() {
  const { loading, devData } = useAdminState().productionList;
  const [data, setData] = useState([]);
  const [tagData, setTagData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [formatData, setFormatData] = useState(null);

  useEffect(() => {
    getProductionList("2024", "all");
  }, []);

  useEffect(() => {
    setData(
      devData?.filter(
        (i) =>
          i.stage === "Implementing" &&
          moment(i.finishDate, "DD/MM/YYYY").isAfter(new Date())
      )
    );

    const groupByDomainWithTagCount = (dataArray) => {
      return dataArray?.reduce((acc, currentItem) => {
        const { domain, tags } = currentItem;

        if (!acc[domain]) {
          acc[domain] = { tagsCount: {}, items: [] };
        }

        if (!acc[domain].tagsCount[tags]) {
          acc[domain].tagsCount[tags] = 0;
        }

        acc[domain].tagsCount[tags] += 1;
        acc[domain].items.push(currentItem);

        return acc;
      }, {});
    };

    const countTagsByValue = (dataArray) => {
      return dataArray?.reduce((acc, currentItem) => {
        const { tags } = currentItem;

        if (tags) {
          if (!acc[tags]) {
            acc[tags] = 0;
          }
          acc[tags] += 1;
        }

        return acc;
      }, {});
    };

    const groupedData = groupByDomainWithTagCount(devData);
    const tagCounts = countTagsByValue(devData);

    setTagData(tagCounts);
    setChartData(groupedData);
  }, [devData]);

  const filteredSkill = DEV_SKILLS.filter((s) => s.primary !== "0");
  const labels = filteredSkill.map((s) => s.skillName);
  const skillData = {
    labels,
    datasets: [
      {
        label: "Primary",
        data: filteredSkill.map((p) => p.primary),
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "Secondary",
        data: filteredSkill.map((p) => p.secondary),
        backgroundColor: "rgb(75, 192, 192)",
      },
    ],
  };

  useEffect(() => {
    if (chartData) {
      setFormatData(formatChartData(chartData));
    }
  }, [chartData]);

  const formatChartData = (data) => {
    const labels = Object.keys(data);
    const tags = new Set();

    Object.values(data).forEach((domainData) => {
      Object.keys(domainData.tagsCount).forEach((tag) => {
        if (tag !== null) {
          tags.add(tag);
        }
      });
    });

    const datasets = Array.from(tags).map((tag) => {
      return {
        label: tag,
        data: labels.map((domain) => data[domain].tagsCount[tag] || 0),
        backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
          Math.random() * 255
        )}, ${Math.floor(Math.random() * 255)}, 0.5)`,
      };
    });

    return {
      labels,
      datasets,
    };
  };

  return (
    <Wrapper>
      <Container className="container">
        <div className="page-header">
          <h2>Local Dev Dashboard</h2>
          <div className="flex">
            <Breadcrumb page="Local Dev Dashboard" link="#" />
          </div>
        </div>

        {loading ? (
          <Loading />
        ) : (
          <Box sx={{ flexGrow: 1 }}>
            <div className="dashboard-wrapper team-dashboard">
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <div className="summary-item project-sum">
                    <div className="flex">
                      <h4 className="no-border">
                        <StarIcon />
                        Project Summary
                      </h4>
                    </div>
                    <div className="detail-item flex no-border">
                      <div className="item ontrack">
                        <CheckBadgeIcon />
                        <p>Completed</p>
                        <h3>
                          {
                            devData?.filter((i) => i.stage === "Completed")
                              .length
                          }
                        </h3>
                      </div>

                      <div className="item inprogress">
                        <Cog6ToothIcon />
                        <p>Inprogress</p>
                        <h3>
                          {
                            devData?.filter((i) => i.stage === "Implementing")
                              .length
                          }
                        </h3>
                      </div>

                      <div className="item at-risk">
                        <ClipboardDocumentListIcon />
                        <p>Ready to Develop</p>
                        <h3>
                          {
                            devData?.filter(
                              (i) => i.stage === "Ready to Develop"
                            ).length
                          }
                        </h3>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="summary-item">
                    <div className="flex">
                      <h4 className="no-border">
                        <BuildingOffice2Icon />
                        By Domain
                      </h4>
                      <h4 className="no-border">
                        <span>
                          <ArrowTopRightOnSquareIcon
                            onClick={() => navigate("/projectList")}
                          />
                        </span>
                      </h4>
                    </div>

                    {formatData && (
                      <div className="detail-item flex no-border">
                        <StackedBarChart data={formatData} />
                      </div>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} md={2}>
                  <div className="summary-item sdt-container">
                    <div className="flex">
                      <h4 className="no-border center">SDT vs Non SDT</h4>
                    </div>

                    {tagData && (
                      <div className="detail-item center no-border">
                        <span className="top">{tagData?.SDT}</span>
                        <span>{tagData["NON - SDT"]}</span>
                      </div>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} md={5}>
                  <div className="summary-item">
                    <div className="flex">
                      <h4 className="no-border">
                        <ClipboardDocumentListIcon />
                        Tasks
                      </h4>
                    </div>

                    <div className="detail-item flex no-border scroll">
                      <TableComp
                        link="/project-details?id="
                        header={["Project Name", "SDMs", "Dev Finish"]}
                        data={data}
                        task
                      />
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={5}>
                  <div className="summary-item">
                    <div className="flex">
                      <h4 className="no-border">
                        <ClipboardDocumentListIcon />
                        Malaysia Projects
                      </h4>
                    </div>

                    <div className="detail-item flex no-border scroll">
                      <TableComp
                        link="/project-details?id="
                        header={["Project Name", "Status"]}
                        data={devData?.filter(
                          (i) =>
                            i?.country?.includes("Malaysia") && i.projectName
                        )}
                      />
                    </div>
                  </div>
                </Grid>

                {/* <Grid item xs={12} md={12}>
                  <div className="summary-item">
                    <div className="flex">
                      <h4>Cost Saving Projects</h4>
                    </div>

                    <div className="detail-item flex no-border">
                      <table>
                        <thead>
                          <tr>
                            <th>Project</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {COST_SAVING_DATA.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <p>{item.name}</p>
                              </td>
                              <td>
                                <p>{item.status}</p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Grid> */}

                <Grid item xs={12} md={12}>
                  <div className="summary-item">
                    <div className="flex">
                      <h4 className="no-border">
                        <UserGroupIcon />
                        Teams
                      </h4>
                    </div>
                    <div className="detail-item flex no-border">
                      <table>
                        <thead>
                          <tr>
                            <th>Team Name</th>
                            <th>SDM</th>
                            <th className="center"># of Developers</th>
                          </tr>
                        </thead>
                        <tbody>
                          {DEV_TEAMS.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Link to={item.link}>
                                  <p>
                                    {item.teamName}
                                    <br />
                                    <span>{item.areas}</span>
                                  </p>
                                </Link>
                              </td>
                              <td>
                                <p>{item.sdm}</p>
                              </td>
                              <td>
                                <p className="center">{item.developers}</p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="summary-item">
                    <div className="flex">
                      <h4 className="no-border">
                        <SparklesIcon />
                        Skill Sets
                      </h4>
                    </div>
                    <div className="detail-item flex no-border">
                      <StackedBarChart data={skillData} />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Box>
        )}
      </Container>
    </Wrapper>
  );
}

export default LocalDevDashboard;
